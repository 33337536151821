import Image from "next/image";
import { cn } from "@/lib/utils";
import React from "react";
import { IoBook } from "react-icons/io5";
import { Book } from "@/graphql/generated/types";
import Link from "next/link";
type BookCoverProps = {
  book?: {
    primaryImageId?: Book["primaryImageId"];
    title?: Book["title"];
    slug?: Book["slug"];
  };
  hideFallback?: boolean;
  fallBackSize?: number;
  className?: string;
  style?: React.CSSProperties;
};
const BookCover: React.FC<BookCoverProps> = props => {
  const {
    book,
    hideFallback,
    fallBackSize,
    className,
    style,
    ...rest
  } = props;
  const imageWrapperClass = cn("relative aspect-[2/3] w-full", className);
  const imageUrl = book?.primaryImageId && `https://images.kaguya.io/books/${book.primaryImageId}-250w.webp`;
  return <Link href={book?.slug ? `/books/${book?.slug}` : ""} data-sentry-element="Link" data-sentry-component="BookCover" data-sentry-source-file="BookCover.tsx">
      <div className={imageWrapperClass} style={{
      ...style
    }}>
        {imageUrl ? <Image className={cn("object-cover object-center", imageWrapperClass)} src={imageUrl} alt={book.title || "Book cover"} fill {...rest} /> : <BookCoverFallback hideFallback={hideFallback} fallBackSize={fallBackSize} />}
      </div>
    </Link>;
};
export default BookCover;
type BookCoverFallbackProps = {
  fallBackSize?: number;
  hideFallback?: boolean;
};
const BookCoverFallback = ({
  fallBackSize,
  hideFallback
}: BookCoverFallbackProps) => {
  if (hideFallback) {
    return null;
  }
  return <div className="absolute inset-0 flex items-center justify-center bg-white/[6%] rounded-[6px]" data-sentry-component="BookCoverFallback" data-sentry-source-file="BookCover.tsx">
      <IoBook size={fallBackSize || 44} className="fill-[#e6e6fa]/50" data-sentry-element="IoBook" data-sentry-source-file="BookCover.tsx" />
    </div>;
};