"use client";

import React, { useState } from "react";
import { Popover, PopoverTrigger, PopoverContent } from "@/components/ui/popover";
import { Drawer, DrawerContent, DrawerTrigger } from "@/components/ui/drawer";
import { RadioGroup, RadioGroupItem } from "@/components/ui/radio-group";
import { useResponsive } from "@/hooks/useResponsive";
import { PopoverArrow } from "@radix-ui/react-popover";
import { Book, ReadingStatusEnum } from "@/graphql/generated/types";
import useSetReadingStatus from "@/hooks/useSetReadingStatus";
import { statuses, getStatusLabel } from "@/utils/statusUtils";
import { toast } from "sonner"; // Using sonner for toasts

interface StatusDropdownProps {
  trigger: React.ReactNode;
  bookIds: Book["id"][];
  initialStatus?: ReadingStatusEnum | null;
  onStatusChange?: (status: ReadingStatusEnum) => void;
}
function StatusDropdown({
  trigger,
  bookIds,
  initialStatus,
  onStatusChange
}: StatusDropdownProps) {
  const {
    isMobile
  } = useResponsive();
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState<ReadingStatusEnum | null>(initialStatus || null);
  const {
    setStatus: setReadingStatus
  } = useSetReadingStatus(bookIds as string[]);
  const handleStatusChange = async (value: ReadingStatusEnum) => {
    // Optimistic UI update
    setStatus(value);
    setOpen(false);
    if (onStatusChange) {
      onStatusChange(value);
    }
    toast.success(`Book has been marked as "${getStatusLabel(value)}".`, {
      style: {
        backgroundColor: "#5069ce",
        color: "white",
        fontSize: "16px"
      }
    });
    try {
      await setReadingStatus(value);
    } catch (e) {
      if (e instanceof Error) {
        toast.error(e.message, {
          style: {
            backgroundColor: "#ff4d4f",
            color: "white",
            fontSize: "16px"
          }
        });
      }

      // Revert status if there was an error
      setStatus(initialStatus || null);
    }
  };
  const content = <>
      <StatusTab status={status} setStatus={handleStatusChange} />
      {/* {error && <div className="text-red-500 mt-2">{error}</div>} */}
    </>;
  return <>
      {isMobile ? <Drawer open={open} onOpenChange={setOpen}>
          <DrawerTrigger asChild>{trigger}</DrawerTrigger>
          <DrawerContent className="p-4 bg-[#10111e] h-[50vh] text-white border-none">
            {content}
          </DrawerContent>
        </Drawer> : <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>{trigger}</PopoverTrigger>
          <PopoverContent className="w-[246px] p-0 rounded-[8px] border border-[#bdbdbd]/10 text-white bg-[#1d1e2f]" sideOffset={8}>
            <PopoverArrow className="w-8 h-4 fill-[#1d1e2f]" />
            {content}
          </PopoverContent>
        </Popover>}
    </>;
}
export default StatusDropdown;
interface StatusTabProps {
  status: ReadingStatusEnum | null;
  setStatus: (status: ReadingStatusEnum) => void;
}
function StatusTab({
  status,
  setStatus
}: StatusTabProps) {
  return <div data-sentry-component="StatusTab" data-sentry-source-file="StatusDropdown.tsx">
      <RadioGroup className="gap-0 p-0 sm:divide-y sm:divide-[#bdbdbd]/10" value={status || ""} onValueChange={setStatus} data-sentry-element="RadioGroup" data-sentry-source-file="StatusDropdown.tsx">
        {statuses.map(statusOption => <div key={statusOption.value} className="flex items-center hover:bg-[#10111e]/50 px-3.5 gap-2.5">
            <RadioGroupItem className="size-[18px]" value={statusOption.value} id={statusOption.value} aria-label={statusOption.label} />
            <div>{statusOption.icon /* Colorful icon */}</div>
            <label className="text-sm w-full h-full py-4 font-medium" htmlFor={statusOption.value}>
              {statusOption.label}
            </label>
          </div>)}
      </RadioGroup>
    </div>;
}