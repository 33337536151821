import { useMutation } from "@apollo/client"
import {
  ReadingStatusEnum,
  SetReadingStatusesDocument,
  SetReadingStatusesMutation,
  SetReadingStatusesMutationVariables,
} from "@/graphql/generated/types"

function useSetReadingStatus(bookIds: string[]) {
  const [setReadingStatusMutation] = useMutation<
    SetReadingStatusesMutation,
    SetReadingStatusesMutationVariables
  >(SetReadingStatusesDocument)

  const setStatus = async (status: ReadingStatusEnum) => {
    await setReadingStatusMutation({
      variables: {
        bookIds: bookIds,
        status: status,
      },
      optimisticResponse: {
        setReadingStatuses: {
          __typename: "ShelfBooksUpdateResponse",
          successfulBooks: bookIds.length,
          totalBooksProcessed: bookIds.length,
        },
      },
      update: (cache) => {
        // Update the cache for each book
        bookIds.forEach((bookId) => {
          cache.modify({
            id: cache.identify({ __typename: "Book", id: bookId }),
            fields: {
              myReadingStatus: () => status,
            },
          })
        })
      },
    })
  }

  return {
    setStatus,
  }
}

export default useSetReadingStatus
