import React from "react";
import Link from "next/link";
import { FaChevronRight } from "react-icons/fa";
import { cn } from "@/lib/utils";
interface BookCarouselHeaderProps {
  title: string;
  url?: string;
  hideMore?: boolean;
}
const BookCarouselHeader: React.FC<BookCarouselHeaderProps> = ({
  title,
  url,
  hideMore
}) => <div className={cn("mb-5 flex items-center gap-5")} data-sentry-component="BookCarouselHeader" data-sentry-source-file="BookCarouselHeader.tsx">
    <Link href={url || ""} className="text-xl sm:text-2xl font-semibold text-white" data-sentry-element="Link" data-sentry-source-file="BookCarouselHeader.tsx">
      {title}
    </Link>
    {!hideMore && <Link href={url || ""} className="font-medium text-white underline-offset-[5px] hover:underline flex items-center gap-1">
        <span>See more</span>
        <FaChevronRight size={12} />
      </Link>}
  </div>;
export default BookCarouselHeader;