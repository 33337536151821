"use client";

import React from "react";
import { Carousel, CarouselContent, CarouselItem } from "@/components/ui/carousel";
import { Skeleton } from "@/components/ui/skeleton";
import Link from "next/link";
import { cn } from "@/lib/utils";
import BookCover from "./BookCover";
import BookCarouselHeader from "./BookCarouselHeader";
import useUIStore from "@/store/useUIStore";
import { Book } from "@/graphql/generated/types";
interface BookCarouselProps {
  className?: string;
  title: string;
  url?: string;
  books: Book[];
  hideMore?: boolean;
}
const BookCarousel: React.FC<BookCarouselProps> = ({
  className,
  title,
  url,
  books,
  hideMore
}) => {
  const isSkeletonLoading = useUIStore(state => state.isSkeletonLoading);
  const tempBooks = React.useMemo(() => {
    const clonedBooks: (Book | null)[] = [...books];
    while (clonedBooks.length < 7) {
      clonedBooks.push(null);
    }
    return clonedBooks;
  }, [books]);
  return <div className={cn("", className)} data-sentry-component="BookCarousel" data-sentry-source-file="BookCarousel.tsx">
      {isSkeletonLoading ? <Skeleton className="h-8 w-[300px] mb-5" /> : <BookCarouselHeader title={title} url={url} hideMore={hideMore} />}
      <Carousel opts={{
      align: "start",
      slidesToScroll: "auto",
      watchDrag: false
    }} className="max-sm:hidden" data-sentry-element="Carousel" data-sentry-source-file="BookCarousel.tsx">
        <CarouselContent className="-ml-5 max-sm:hidden" data-sentry-element="CarouselContent" data-sentry-source-file="BookCarousel.tsx">
          {tempBooks.map((book, index) => <CarouselItem key={index} className="pl-5 max-sm:hidden md:basis-1/2 lg:basis-[14.25%]">
              <Link href={book ? `/books/${book.slug}` : "#"} className="aspect-[1/1.5] h-full w-full overflow-hidden rounded-[8px]">
                {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : book ? <BookCover book={book} className="h-full w-full rounded-[8px] object-cover object-center" /> : null}
              </Link>
            </CarouselItem>)}
        </CarouselContent>
      </Carousel>

      {/* Mobile View */}
      <div className="grid grid-cols-3 sm:hidden gap-3">
        {tempBooks.slice(0, 9).map((book, index) => <div key={index} className="aspect-[1/1.5] h-full w-full overflow-hidden rounded-[4px] sm:rounded-[8px]">
            {isSkeletonLoading ? <Skeleton className="h-full w-full" /> : book ? <BookCover book={book} className="h-full w-full rounded-[8px] object-cover object-center" /> : null}
          </div>)}
      </div>
    </div>;
};
export default React.memo(BookCarousel);
BookCarousel.displayName = "BookCarousel";